<template lang="pug">
.check-box(@click="checkBox")
    img(:src="checked ? checkedImg : checkImg")
    span {{text}}
</template>

<script>
export default {
    name: "DefaultCheckBox",
    props: {
        text: {
            type: String,
            default: null
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checkImg: require('@/assets/images/check-square-blank.svg'),
            checkedImg: require('@/assets/images/check-square.svg')
        }
    },
    methods: {
        checkBox() {
            return this.$emit("update:checked", !this.checked)
        }
    }
}
</script>

<style lang="scss" scoped>
.check-box {
    cursor: pointer;
    user-select: none;
    display: grid;
    grid-template-columns: 12px auto;
    grid-column-gap: 5px;
    align-items: center;

    span {
        color: #434242;
        font-size: 12px;
    }
}
</style>
