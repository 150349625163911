<template lang="pug">
.cont(v-if="!success")
    DefaultCheckBox.item(text="კომპანია" v-model:checked="isCompany")
    template(v-if="isCompany")
        DefaultInput.item(text="კომპანია" v-model:value="company.company_name")
        DefaultInput.item(text="საიდენტიფიკაციო კოდი" v-model:value="company.identification_number")
        DefaultInput.item(text="საკონტაქტო ემაილი" v-model:value="company.contact_email")
        DefaultInput.item(text="საკონტაქტო ნომერი" v-model:value="company.contact_number")
    template(v-else)
        DefaultInput.item(text="პირადი ნომერი" v-model:value="physical.id_number" )
    DefaultTextarea.item(text="აღწერა" placeholderText="აღწერა" v-model:value="description")
    DefaultImageInput.item(text="ფოტო" text2="ფოტოს ატვირთვა" v-model:value="image")
    DefaultImageInput.item(v-if="!isCompany" text="იდენტობის დამადასტურებელი დოკუმენტი" text2="ფოტოს ატვირთვა" v-model:value="physical.id_image")
    .item
        DefaultButton(text="ᲒᲐᲒᲖᲐᲕᲜᲐ" @clicked="sendData")
form-notification(v-else)
</template>

<script>
import DefaultCheckBox from "../layout/DefaultCheckBox";
import DefaultInput from "../layout/DefaultInput";
import DefaultButton from "../layout/DefaultButton";
import DefaultTextarea from "../layout/DefaultTextarea";
import DefaultImageInput from "../layout/DefaultImageInput";
import FormNotification from "./FormNotification";
import {ajax, apiUrls} from "@/api/urls";

export default {
    name: "UserVerificationForm",
    components: {
        DefaultCheckBox,
        DefaultInput,
        DefaultButton,
        DefaultTextarea,
        DefaultImageInput,
        FormNotification
    },
    data() {
        return {
            isCompany: false,
            success: false,
            description: '',
            image: '',
            physical: {
                id_number: '',
                selfie: '',
                id_image: '',
                description: ''
            },
            company: {
                company_name: '',
                identification_number: '',
                contact_number: '',
                contact_email: '',
                description: '',
                company_image: ''
            }
        }
    },
    methods: {
        prepareData(data) {
            const formData = new FormData();

            Object.keys(data).forEach((el) => {
                if (el === "selfie" || el === "id_image" || el === "company_image") {
                    formData.append(el, data[el].imageFile);
                } else {
                    formData.append(el, data[el]);
                }
            })

            return formData
        },
        sendData() {
            const data = this.isCompany ? this.company : this.physical
            const url = this.isCompany ? apiUrls.companyEmployer : apiUrls.physicalEmployer
            return ajax.post(url, this.prepareData(data)).then(() => {
                this.success = true
            }).catch(() => {
            })
        }
    },
    watch: {
        description(val) {
            this.physical.description = val
            this.company.description = val
        },
        image(val) {
            this.physical.selfie = val
            this.company.company_image = val
        }
    }
}
</script>

<style lang="scss" scoped>
.cont {
    margin: 33px 0;

    .item {

        &:first-child {
            padding-left: 4px;
        }

        &:not(:first-child) {
            margin-top: 18px;
        }
    }

}
</style>
