<template lang="pug">
.notification
    img(src="@/assets/images/check-circle.svg")
    span თქვენი ინფორმაცია წარმატებით შეინახა, დაელოდეთ უკუკავშირს
</template>

<script>
export default {
    name: "FormNotification"
}
</script>

<style lang="scss" scoped>
.notification {
    padding: 30px 0;

    img {
        margin-right: 10px;
    }

    span {
        font-size: 12px;
        color: #434242;
    }
}
</style>
