<template lang="pug">
form-layout(title="ᲕᲔᲠᲘᲤᲘᲙᲐᲪᲘᲐ")
	template(#right)
		UserVerificationForm
</template>

<script>
import FormLayout from "../components/layout/FormLayout";
import UserVerificationForm from "@/components/forms/UserVerificationForm";

export default {
	name: "EmployerVerification",
	components: {
		FormLayout,
		UserVerificationForm
	}
}
</script>

<style lang="scss" scoped>

</style>
